<template>
  <div id="PageLogin">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 mx-auto">
        <div class="card shadow-lg">
          <div class="card-header bg-color-primary-4 text-white py-1">
            <span
              class="d-inline-flex align-middle mdi mdi-account-check mdi-24px me-1"
            ></span
            >ورود به حساب کاربری
          </div>
          <div class="card-body">
            <form @submit.prevent="doLogin">
              <div class="mb-3">
                <label for="usernameInput" class="form-label">نام کاربری</label>
                <input
                  type="text"
                  class="form-control"
                  id="usernameInput"
                  v-model="username"
                  :class="{
                    'is-invalid': usernameE === true,
                    'is-valid': usernameE === false,
                  }"
                />
                <div v-if="usernameE" class="invalid-feedback">
                  {{ usernameEM }}
                </div>
              </div>
              <div class="mb-3">
                <label for="passwordInput" class="form-label">کلمه عبور</label>
                <input
                  type="password"
                  class="form-control"
                  id="passwordInput"
                  v-model="password"
                  :class="{
                    'is-invalid': passwordE === true,
                    'is-valid': passwordE === false,
                  }"
                />
                <div v-if="passwordE" class="invalid-feedback">
                  {{ passwordEM }}
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-secondary w-100"
                @click="doLogin"
              >
                ورود به سیستم
              </button>
            </form>
          </div>
          <div class="card-footer text-muted text-center">
            <router-link to="/register"
              >یک حساب کاربری جدید ایجاد کنید.</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageLogin",
  data() {
    return {
      username: "",
      password: "",
      usernameE: null,
      passwordE: null,
      usernameEM: null,
      passwordEM: null,
      access: null,
    };
  },
  methods: {
    doLogin() {
      this.access = true;
      if (this.username.length < 5) {
        this.access = false;
        this.usernameE = true;
        if (this.username.length === 0) {
          this.usernameEM = "نام کاربری ضروری است";
        } else {
          this.usernameEM = "نام کاربری باید بیشتر از 5 کاراکتر باشد.";
        }
      } else {
        this.usernameE = false;
        this.usernameEM = "";
      }
      if (this.password.length < 6) {
        this.access = false;
        this.passwordE = true;
        if (this.password.length === 0) {
          this.passwordEM = "کلمه عبور ضروری است";
        } else {
          this.passwordEM = "کلمه عبور باید بیشتر از 6 کاراکتر باشد.";
        }
      } else {
        this.passwordE = false;
        this.passwordEM = "";
      }
      if (this.access) {
        console.log(this.username + " - " + this.password);
        this.$store.commit("auth/login", this.username + "-" + this.password);
        this.$router.push("/profile");
      }
    },
  },
};
</script>
<style scoped>
.card-footer a {
  font-size: 12px;
}
</style>
